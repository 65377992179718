import React from 'react'

export default function MobileServiceHead({ txt }) {
  return (
    <div className='m-service-head'>

      <h6>What we do</h6>

      <h4>{ txt }</h4>
      
    </div>
  )
}
